

/* ******* Banner ******* */

@media (max-width:1024px) {
   .farasMainTitle{
      font-size: xx-large;
   }
   
}

@media (max-width:1300px) {
   .resAboutCart{
      gap: 5rem;
   }
   
}

/* ******* gallerysection ******* */

@media(max-width:1485px){
   .gallerySlider{
      width: 220px;
      height: 220px;
   }
}
@media (max-width:1275px) {
   .gallerySlider{
      width: 200px;
      height: 200px;
   }
   .galleryMainGap{
      gap: 10px;
   }
   .videoTagMargin{
      margin: 0 !important;
   }
}