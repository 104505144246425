@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
   font-family: 'iranyekan-font';
   src: url('./assets/fonts/iranyekanwebregular.woff2') format('woff2'),
      url('./assets/fonts/iranyekanwebregular.woff') format('woff');
}

@font-face {
   font-family: 'roboto-font';
   src: url('./assets/fonts/robotobold.woff2') format('woff2'),
      url('./assets/fonts/robotobold.woff') format('woff');
}

@font-face {
   font-family:'nian-font' ;
   src: url('./assets/fonts/Nian.ttf') format('ttf'),
}
:root {
   --main-background: #1a1a1a;
   --white: #f3f3f3;
   --primary-color: #9c7952;
   background-color: var(--main-background) !important;
   font-family: "iranyekan-font";
}

:root::-webkit-scrollbar {
   display: none;
}

html {
   scroll-behavior: smooth;
 }