.blink {
   animation: blinkAnimation 1s infinite;
   
 }
 
 @keyframes blinkAnimation {
   0% {
     opacity: 1;
   }
   50% {
     opacity: .3;
   }
   100% {
     opacity: 1;
   }
 } 