
.ui{
}

.ui > li{
   font-size: 21px;
   color: var(--white);
}

.ui > li :hover{
   color: var(--primary-color);
   transition: all 100ms ease-in;
}
.ui > li :focus{
   color:var(--primary-color) ;
}

.linkBox{}
.linkBox:hover{
   display: flex;
   align-items: center;
   justify-content: space-around;
   transition: all 1s ease-in !important;
}

@media (max-width:1590px){
   .btnBox{
      flex-basis: 0;
   }
   .ui{
      gap:50px;
   }
   .ui > li{
      font-size: 18px;
   }
}
@media (max-width:1383px){
   
   .ui {
      gap: 40px;
   }
}
@media (max-width:1130px){
   .logoNavContainer{
      gap:40px;
   }
   .ui > li{
      font-size: 16px;
   }
   .ui {
      gap: 30px;
   }
}

.backDrop{
   backdrop-filter: blur(7px);
}